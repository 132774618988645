<template>
  <el-dialog title="第三方类型映射选择表"
             :close-on-click-modal="false"
             :visible.sync="thirdPartyDialog"
             width="50%"
             append-to-body>
    <div>
      <span style="width: 10%">第三方名称:</span>
      <el-input v-model="thirdPartyName" style="margin-left: 10px; width: 40%" disabled></el-input>
      <div style="margin-top: 20px">
        <span style="margin-left: 15%">数据项名称</span>
        <span style="margin-left: 40%">属性列表</span>
        <div style="margin-left: 1%;margin-top: 1%;">
          <div>
            <el-row :gutter="70">
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      prefix-icon="el-icon-search"-->
<!--                      @keyup.native="dFSearch"-->
<!--                      ref="dFieldSearch"-->
<!--                      v-model="deviceFieldSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;"-->
<!--                      placeholder="输入关键字搜索"/>-->
                  <el-table
                      ref="selectDeviceField"
                      :data="deviceFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="deviceFieldSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectDeviceStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据项名称"
                        min-width="70%">
<!--                      <template slot-scope="scope">-->
<!--                        <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
<!--                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
<!--                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
<!--                      </template>-->
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="数据项类型"
                        min-width="70%">
                    </el-table-column>
                  </el-table>
<!--                  <el-button @click="deviceAdd" size="mini" type="primary"-->
<!--                             style="width: 100%;margin-top: 1%;margin-bottom: 1%;" round>添 加-->
<!--                  </el-button>-->
                </div>
              </el-col>
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      @keyup.native="thirdPartySearch"-->
<!--                      ref="thirdPartyInfoSearch"-->
<!--                      v-model="thirdPartyInfoSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;border-radius:10px;"-->
<!--                      placeholder="输入关键字搜索" prefix-icon="el-icon-search"/>-->
                  <el-table
                      ref="selectThirdParty"
                      :data="thirdPartyInfoData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="thirdPartySelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectThirdPartyStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据列表"
                        min-width="70%">
<!--                      <template slot-scope="scope">-->
<!--                        <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
<!--                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
<!--                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
<!--                      </template>-->
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-row :gutter="50">
          <el-col :span="17">
            <div>
              <el-table
                  :data="selectThirdPartyData"
                  height="250"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="selectDeviceField"
                    label="已选择设备字段"
                    min-width="40%">
                </el-table-column>
                <el-table-column
                    prop="selectThirdPartyField"
                    label="已选择第三方"
                    min-width="40%">
                </el-table-column>
                <el-table-column label="操作" min-width="20%">
                  <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDeleteThirdParty(scope.$index, scope.row)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <el-button @click="selectThirdPartyMap" style="width: 100%;height: 5%;padding:5px 10px;font-size: 10px"
                         type="primary" round>确定
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="thirdPartyDialog = false">取 消</el-button>
       <el-button type="primary" @click="thirdPartySure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>

export default {
  name: "thirdParty",
  props:["workNode","nowReflectData","mapData","nowEachTaskData","taskData","allTaskData","thirdPartyData"],
  data(){
    return{
      thirdPartyDialog:false,
      thirdPartyName: '',
      deviceFieldSearch: '',
      deviceFieldData: [],
      deviceFieldDataCopy: [],
      thirdPartyInfoSearch: '',
      thirdPartyInfoDataCopy: [],
      thirdPartyInfoData:[],
      selectThirdParty: {},
      selectThirdPartyData: [],
      selectThirdPartySecond: {},
      selectDeviceField: {},
      selectDeviceSecondField: {},
      nowOneReflectThirdParty: {},
      oneReflectThirdPartyName_temp: {
        ThirdPartyName: '',
        thirdParty: []
      },
      nowOneReflectThirdPartyName: {},
      oneReflectThirdParty_temp: {
        thirdParty: '',
        thirdPartyField: '',
        fieldName: '',
        fieldId: ''
      },
    }
  },
  methods:{

    open(data) {
      this.selectThirdPartyData=data
      this.thirdPartyDialog = true
    },
    close() {
      this.thirdPartyDialog = false
    },
    loadDeviceFieldData(id,data) {
      console.log("sdad",data)

      this.thirdPartyName = data.name
      this.deviceFieldData = [];

      this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          let data = {
            name: response.data.data[i].name,
            type: response.data.data[i].type,
            // fieldId: response.data[i].fieldId,
            fieldId: response.data.data[i].id,
            flag: '未选中',
            seen: false
          }
          if(data.type === undefined || data.type === ""){
            data.type = "未设定"
          }
          console.log("data123:"+data)
          this.deviceFieldData.push(data)
        }
      }).then(error => {
      })
      // let temp = {
      //   coal: this.workNode.parent.parent.parent.parent.data.name,
      //   system: this.workNode.parent.parent.parent.data.name,
      //   subsystem: this.workNode.parent.parent.data.name,
      //   device: this.workNode.parent.data.name,
      //   subDevice: this.workNode.data.name,
      //   subEquipmentId: this.workNode.data.subEquipmentId
      // }
      // this.postRequest("/addDataAccessTask/attributeSubDevice", temp).then(response => {
      //   for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
      //     let data = {
      //       name: response.data[i].name,
      //       fieldId: response.data[i].fieldId,
      //       type: response.data[i].type,
      //       flag: '未选中',
      //       seen: false
      //     }
      //     this.deviceFieldData.push(data)
      //   }
      // }).then(error => {
      // })
      for (let i = 0; i < this.thirdPartyData.length; i++) {
        if (id === this.thirdPartyData[i].id) {
          this.thirdPartyName = data.name
          let infoList=data.dataList.split("||")
          this.thirdPartyInfoData = []
          this.selectThirdPartyData = []
          for (let i = 0; i < infoList.length; i++) {
            let data = {
              name: infoList[i],
              seen: false,
              flag: '未选中'
            }
            this.thirdPartyInfoData.push(data)
          }
          let same = 0
          for (let m = 0; m < this.nowReflectData.ThirdParty.length; m++) {
            if (this.thirdPartyData[i].thirdPartyName === this.nowReflectData.ThirdParty[m].ThirdPartyName) {
              this.nowOneReflectThirdPartyName = JSON.parse(JSON.stringify(this.nowReflectData.ThirdParty[m]))
              same = 1
              break
            }
          }
          if (same === 0) {
            this.nowOneReflectThirdPartyName = JSON.parse(JSON.stringify(this.oneReflectThirdPartyName_temp))
            this.nowOneReflectThirdPartyName.ThirdPartyName = this.thirdPartyData[i].thirdPartyName
            this.nowReflectData.ThirdParty.push(this.nowOneReflectThirdPartyName)
          }
          console.log("his.nowReflectData",this.nowReflectData)
          for (let m = 0; m < this.nowReflectData.ThirdParty.length; m++) {
            if (this.thirdPartyData[i].thirdPartyName === this.nowReflectData.ThirdParty[m].ThirdPartyName) {
              for (let n = 0; n < this.nowReflectData.ThirdParty[m].thirdParty.length; n++) {
                if (this.thirdPartyData[i].thirdPartyName === this.nowReflectData.ThirdParty[m].thirdParty[n].thirdParty) {
                  let data = {
                    selectThirdParty: this.nowReflectData.ThirdParty[m].thirdParty[n].thirdParty,
                    selectThirdPartyField: this.nowReflectData.ThirdParty[m].thirdParty[n].thirdPartyField,
                    selectDeviceField: this.nowReflectData.ThirdParty[m].thirdParty[n].fieldName,
                    fieldId: this.nowReflectData.ThirdParty[m].thirdParty[n].fieldId,
                  }
                  this.selectThirdPartyData.push(data)
                }
              }
              break;
            }
          }

        }
      }
      setTimeout(() => {
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.thirdPartyInfoData.length; j++) {
            if (this.mapData[i].tableData === this.thirdPartyInfoData[j].name&&this.mapData[i].tableName===this.thirdPartyName) {
              this.thirdPartyInfoData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.mapData[i].deviceData === this.deviceFieldData[j].name) {
              this.deviceFieldData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.thirdPartyInfoData.length; i++) {
          if (this.thirdPartyInfoData[i].flag === '已选中2') {
            this.$refs.selectThirdParty.toggleRowSelection(this.thirdPartyInfoData[i], true)
          }
          this.$options.methods.selectThirdPartyStatus(this.thirdPartyInfoData[i])
        }
        for (let j = 0; j < this.deviceFieldData.length; j++) {
          if (this.deviceFieldData[j].flag === '已选中2') {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[j], true)
          }
          this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
        }
      }, 500)

      this.thirdPartyDialog=true
      // if (this.allTaskData.accessSource === '第三方') {
      //   this.selectThirdPartyData = []
      //   this.thirdPartyInfoData = []
      //
      //   for (let i = 0; i < this.thirdPartyData.length; i++) {
      //     if (id === this.thirdPartyData[i].id) {
      //       this.thirdPartyName = this.thirdPartyData[i].name
      //       let data = {
      //         name: this.thirdPartyData[i].name,
      //         seen: false,
      //         flag: '未选中'
      //       }
      //       this.thirdPartyInfoData.push(data)
      //     }
      //   }
      //   setTimeout(() => {
      //     for (let i = 0; i < this.selectThirdPartyData.length; i++) {
      //       for (let j = 0; j < this.thirdPartyInfoData.length; j++) {
      //         if (this.selectThirdPartyData[i].selectThirdParty === this.thirdPartyInfoData[j].name) {
      //           this.thirdPartyInfoData[j].flag = '已选中'
      //         }
      //       }
      //       for (let j = 0; j < this.deviceFieldData.length; j++) {
      //         if (this.selectThirdPartyData[i].selectDeviceField === this.deviceFieldData[j].name) {
      //           this.deviceFieldData[j].flag = '已选中'
      //         }
      //       }
      //     }
      //     for (let j = 0; j < this.thirdPartyInfoData.length; j++) {
      //       if (this.thirdPartyInfoData[j].flag === '已选中') {
      //         this.$refs.selectThirdParty.toggleRowSelection(this.thirdPartyInfoData[j], true)
      //       }
      //       this.$options.methods.selectThirdPartyStatus(this.thirdPartyInfoData[j])
      //
      //     }
      //     for (let j = 0; j < this.deviceFieldData.length; j++) {
      //       if (this.deviceFieldData[j].flag === '已选中') {
      //         this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[j], true)
      //       }
      //       this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
      //     }
      //   }, 500)
      // }
    },
    loseFocus(index, row) {
      row.seen = false;
    },
    cellClick(row, column) {
      row.seen = true;
    },
    // dFSearch() {
    //   this.deviceFieldSearch = this.$refs.dFieldSearch.value;
    //   this.deviceFieldData = this.deviceFieldDataCopy;
    //   if (this.deviceFieldSearch === '') {
    //     this.deviceFieldData = this.deviceFieldDataCopy;
    //   } else {
    //     let data = []
    //     this.deviceFieldData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.deviceFieldSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.deviceFieldData = data
    //   }
    // },
    deviceFieldSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectDeviceField = row
      if (this.selectDeviceSecondField.fieldId === undefined) {
        this.selectDeviceSecondField = this.selectDeviceField
      } else {
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceSecondField.fieldId === this.deviceFieldData[i].fieldId) {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.deviceFieldData[i].flag = '未选中'
            break;
          }
        }
        this.selectDeviceSecondField = this.selectDeviceField
      }
      if (row.flag === '未选中' && this.selectDeviceSecondField === this.selectDeviceField) {
        this.selectDeviceSecondField = {}
        this.selectDeviceField = {}
      }
    },
    selectDeviceStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },
    // deviceAdd() {
    //   let data = {
    //     name: '',
    //     seen: false
    //   }
    //   this.deviceFieldData.push(data)
    // },

    handleDeleteThirdParty(index, row) {
      //删除表内映射信息
      this.selectThirdPartyData.splice(index, 1)
      //删除总映射信息
      for (let i = 0; i < this.mapDataThirdParty.length; i++) {
        if (row.selectDeviceField === this.mapDataThirdParty[i].deviceData) {
          this.mapDataThirdParty.splice(i, 1);
          break;
        }
      }
      //恢复表内附属设备和数据源字段的状态
      for (let i = 0; i < this.deviceFieldData.length; i++) {
        if (row.selectDeviceField === this.deviceFieldData[i].name) {
          this.deviceFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
          }, 500)
        }
      }
      for (let i = 0; i < this.thirdPartyData.length; i++) {
        if (row.selectThirdParty === this.thirdPartyData[i].name) {
          this.thirdPartyData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectThirdParty.toggleRowSelection(this.thirdPartyData[i], false)
            this.$options.methods.selectThirdPartyStatus(this.thirdPartyData[i])
          }, 500)
        }
      }

      for (let i = 0; i < this.nowReflectData.thirdParty.length; i++) {
        if (row.selectDeviceField === this.nowReflectData.thirdParty[i].fieldName) {
          this.nowReflectData.thirdParty.splice(i, 1)
          break;
        }
      }
    },
    selectThirdPartyMap() {
      if (this.selectThirdParty.name === undefined || this.selectDeviceField.name === undefined) {
        this.$message({
          message: '映射关系已经添加或映射内容是空的！',
          type: 'warning'
        });
      } else {
        console.log("1")
        for (let i = 0; i < this.nowReflectData.ThirdParty.length; i++) {
          if (this.thirdPartyName === this.nowReflectData.ThirdParty[i].ThirdPartyName) {
            console.log("2")
            this.nowOneReflectThirdPartyName= this.nowReflectData.ThirdParty[i]
          }
        }
        console.log(this.nowOneReflectThirdPartyName)
        if (this.thirdPartyName === this.nowOneReflectThirdPartyName.ThirdPartyName) {
          console.log("2")
          this.nowOneReflectThirdParty = JSON.parse(JSON.stringify(this.oneReflectThirdParty_temp))
          this.nowOneReflectThirdParty.thirdParty = this.thirdPartyName
          this.nowOneReflectThirdParty.thirdPartyField = this.selectThirdParty.name
          this.nowOneReflectThirdParty.fieldName = this.selectDeviceField.name
          this.nowOneReflectThirdParty.fieldId = this.selectDeviceField.fieldId
          this.nowOneReflectThirdPartyName.thirdParty.push(this.nowOneReflectThirdParty)
          //显示一个任务的映射信息
          let data1 = {
            tableData: this.selectThirdParty.name,
            deviceData: this.selectDeviceField.name,
            tableName: this.thirdPartyName,
            fieldId: this.nowOneReflectThirdParty.fieldId
          }
          this.mapData.push(data1)
          let data2 = {
            selectThirdParty: this.thirdPartyName,
            selectDeviceField: this.selectDeviceField.name,
            selectThirdPartyField: this.selectThirdParty.name,
            fieldId: this.nowOneReflectThirdParty.fieldId
          }
          this.selectThirdPartyData.push(data2)
        }

        for (let i = 0; i < this.thirdPartyInfoData.length; i++) {
          if (this.selectThirdParty.name === this.thirdPartyInfoData[i].name) {
            this.thirdPartyInfoData[i].flag = '已选中2'
            this.$options.methods.selectThirdPartyStatus(this.thirdPartyInfoData[i])
            break;
          }
        }
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceField.fieldId === this.deviceFieldData[i].fieldId) {
            this.deviceFieldData[i].flag = '已选中2'
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
            break;
          }
        }
        this.selectThirdParty = {}
        this.selectDeviceField = {}
        this.selectThirdPartySecond = {}
        this.selectDeviceSecondField = {}

        for (let i = 0; i < this.nowReflectData.ThirdParty.length; i++) {
          if (this.thirdPartyName === this.nowReflectData.ThirdParty[i].ThirdPartyName) {
            this.nowReflectData.ThirdParty[i] = JSON.parse(JSON.stringify(this.nowOneReflectThirdPartyName))
          }
        }
        this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))


        for (let i = 0; i < this.taskData.length; i++) {
          if (this.taskData[i].subEquipmentId === this.nowEachTaskData.subEquipmentId) {
            this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
          }
        }
        this.allTaskData.taskData = this.taskData
      }

    },
    thirdPartySure() {
      this.thirdPartyDialog = false
      this.$emit("thirdPartySure",this.selectThirdPartyData)
    },
    // thirdPartySearch() {
    //   this.thirdPartyInfoSearch = this.$refs.thirdPartyInfoSearch.value;
    //   this.thirdPartyInfoData = this.thirdPartyInfoDataCopy;
    //   if (this.thirdPartyInfoSearch === '') {
    //     this.thirdPartyInfoData = this.thirdPartyInfoDataCopy;
    //   } else {
    //     let data = []
    //     this.thirdPartyInfoData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.thirdPartyInfoSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.thirdPartyInfoData = data
    //   }
    // },
    thirdPartySelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      console.log(row)
      this.selectThirdParty = row
      if (this.selectThirdPartySecond.name === undefined) {
        this.selectThirdPartySecond = this.selectThirdParty
      } else {
        for (let i = 0; i < this.thirdPartyInfoData.length; i++) {
          if (this.selectThirdPartySecond.name === this.thirdPartyInfoData[i].name) {
            this.$refs.selectThirdParty.toggleRowSelection(this.thirdPartyInfoData[i], false)
            this.thirdPartyInfoData[i].flag = '未选中'
            break;
          }
        }
        this.selectThirdPartySecond = this.selectThirdParty
      }
      if (row.flag === '未选中' && this.selectThirdPartySecond === this.selectThirdParty) {
        this.selectThirdPartySecond = {}
        this.selectThirdParty = {}
      }
      this.selectThirdParty = row;
    },
    selectThirdPartyStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },
    cellClass(row) {
      console.log("***", row)
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
  },
}
</script>

<style scoped>
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '操作';
  text-align: center;
  line-height: 50px;
}
</style>
