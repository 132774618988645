<template>
  <el-dialog title="表单"
             :close-on-click-modal="false"
             :visible.sync="formDialog"
             width="40%"
             append-to-body>
    <div>
      <span style="width: 10%">表单名称:</span>
      <el-input v-model="formName" style="margin-left: 10px; width: 40%"></el-input>
      <div style="margin-top: 20px">
        <span style="margin-left: 15%">附属设备字段</span>
        <span style="margin-left: 40%">数据源</span>
        <div style="margin-left: 1%;margin-top: 1%;">
          <div>
            <el-row :gutter="70">
              <el-col :span="11">
                <div>
                  <el-input
                      prefix-icon="el-icon-search"
                      @keyup.native="dFSearch"
                      ref="dFieldSearch"
                      v-model="deviceFieldSearch"
                      size="mini"
                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;"
                      placeholder="输入关键字搜索"/>
                  <el-table
                      ref="selectDeviceField"
                      :data="deviceFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      @select="deviceFieldSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        width="55%"
                        :selectable="selectDeviceStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="字段名"
                        width="150%">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" v-if="scope.row.seen"
                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>
                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button @click="deviceAdd" size="mini" type="primary"
                             style="width: 100%;margin-top: 1%;margin-bottom: 1%;" round>添 加
                  </el-button>
                </div>
              </el-col>
              <el-col :span="11">
                <div>
                  <el-input
                      @keyup.native="formSearch"
                      ref="formFieldSearch"
                      v-model="formFieldSearch"
                      size="mini"
                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;border-radius:10px;"
                      placeholder="输入关键字搜索" prefix-icon="el-icon-search"/>
                  <el-table
                      ref="selectForm"
                      :data="formFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      @select="formSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        width="55%"
                        :selectable="selectFormStatus">
                    </el-table-column>
                    <el-table-column
                        prop="formField"
                        label="表单字段"
                        width="150%">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" v-if="scope.row.seen"
                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>
                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-row :gutter="50">
          <el-col :span="17">
            <div>
              <el-table
                  :data="selectFormData"
                  height="250"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="selectDeviceField"
                    label="已选择设备字段"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="selectForm"
                    label="已选择表单字段"
                    width="140">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDeleteForm(scope.$index, scope.row)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <el-button @click="selectFormMap" style="width: 100%;height: 5%;padding:5px 10px;font-size: 10px"
                         type="primary" round>确定
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="formDialog = false">取 消</el-button>
       <el-button type="primary" @click="formSure">确 定</el-button>
      </span>
  </el-dialog>
</template>


<script>
import axios from "axios";

export default {
  name: "form",
  props:["workNode","mapDataForm","nowReflectData","nowEachTaskData","taskData","allTaskData","formData","activeMap"],
  data(){
    return{
      formDialog:false,
      formName: '',
      deviceFieldSearch: '',
      deviceFieldData: [],
      deviceFieldDataCopy: [],
      formFieldSearch: '',
      formFieldDataCopy: [],
      formFieldData:[],
      selectForm: {},
      selectFormData: [],
      nowOneReflectForm: {},
      oneReflectForm_temp: {
        formName: '',
        formField:'',
        fieldName: '',
        fieldId: ''
      },
    }
  },
  methods:{
    open(data) {
      this.selectFormData=data
      this.formDialog = true
    },
    close() {
      this.formDialog = false
    },
    loadDeviceFieldData(id,data) {
      this.formName = data.name
      this.deviceFieldData = [];
      let temp = {
        coal: this.workNode.parent.parent.parent.parent.data.name,
        system: this.workNode.parent.parent.parent.data.name,
        subsystem: this.workNode.parent.parent.data.name,
        device: this.workNode.parent.data.name,
        subDevice: this.workNode.data.name,
        subEquipmentId: this.workNode.data.subEquipmentId
      }
      axios.post("/addDataAccessTask/attributeSubDevice", temp).then(response => {
        for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
          let data = {
            name: response.data[i].name,
            fieldId: response.data[i].fieldId,
            flag: '未选中',
            seen: false
          }
          this.deviceFieldData.push(data)
        }
      }).then(error => {
      })

      if (this.allTaskData.accessSource === '表单') {
        this.selectFormData = []
        this.formFieldData = []

        for (let i = 0; i < this.formData.length; i++) {
          if (id === this.formData[i].id) {
            this.formName = this.formData[i].name
            console.log(this.formData[i])
            console.log(this.formData[i].formField)
            let fieldList=this.formData[i].formField.split("_")
            for(let m=1;m<fieldList.length;m++){
              let data = {
                name: fieldList[m],
                seen: false,
                flag: '未选中'
              }
              this.formFieldData.push(data)
            }
          }
        }
        setTimeout(() => {
          for (let i = 0; i < this.selectFormData.length; i++) {
            for (let j = 0; j < this.formFieldData.length; j++) {
              if (this.selectFormData[i].selectForm === this.formFieldData[j].name) {
                this.formFieldData[j].flag = '已选中'
              }
            }
            for (let j = 0; j < this.deviceFieldData.length; j++) {
              if (this.selectFormData[i].selectDeviceField === this.deviceFieldData[j].name) {
                this.deviceFieldData[j].flag = '已选中'
              }
            }
          }
          for (let j = 0; j < this.formFieldData.length; j++) {
            if (this.formFieldData[j].flag === '已选中') {
              this.$refs.selectForm.toggleRowSelection(this.formFieldData[j], true)
            }
            this.$options.methods.selectFormStatus(this.formFieldData[j])

          }
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.deviceFieldData[j].flag === '已选中') {
              this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[j], true)
            }
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
          }
        }, 500)
      }
    },
    loseFocus(index, row) {
      row.seen = false;
    },
    cellClick(row, column) {
      row.seen = true;
    },
    dFSearch() {
      this.deviceFieldSearch = this.$refs.dFieldSearch.value;
      this.deviceFieldData = this.deviceFieldDataCopy;
      if (this.deviceFieldSearch === '') {
        this.deviceFieldData = this.deviceFieldDataCopy;
      } else {
        let data = []
        this.deviceFieldData.map(msg => {
          //拿当前json的id、name、time去分别跟输入的值进行比较
          //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
          if (msg.name.indexOf(this.deviceFieldSearch) != -1) {
            //然后把当前json添加到list数组中
            data.push(msg);
          }
        })
        this.deviceFieldData = data
      }
    },
    deviceFieldSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectDeviceField = row;

    },
    selectDeviceStatus(row) {
      if (row.flag === '已选中') {
        return false
      }
      return true
    },
    deviceAdd() {
      let data = {
        name: '',
        seen: false
      }
      this.deviceFieldData.push(data)
    },

    handleDeleteForm(index, row) {
      //删除表内映射信息
      this.selectFormData.splice(index, 1)
      //删除总映射信息
      for (let i = 0; i < this.mapDataForm.length; i++) {
        if (row.selectDeviceField === this.mapDataForm[i].deviceData) {
          this.mapDataForm.splice(i, 1);
          break;
        }
      }
      //恢复表内附属设备和数据源字段的状态
      for (let i = 0; i < this.deviceFieldData.length; i++) {
        if (row.selectDeviceField === this.deviceFieldData[i].name) {
          this.deviceFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
          }, 500)
        }
      }
      for (let i = 0; i < this.formData.length; i++) {
        if (row.selectForm === this.formData[i].name) {
          this.formData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectForm.toggleRowSelection(this.formData[i], false)
            this.$options.methods.selectFormStatus(this.formData[i])
          }, 500)
        }
      }

      for (let i = 0; i < this.nowReflectData.form.length; i++) {
        if (row.selectDeviceField === this.nowReflectData.form[i].fieldName) {
          this.nowReflectData.form.splice(i, 1)
          break;
        }
      }
    },
    selectFormMap() {
      let data1 = {
        deviceData: this.selectDeviceField.name,
        formData: this.selectForm.name,
      }
      this.mapDataForm.push(data1)
      let data2 = {
        selectForm: this.selectForm.name,
        selectDeviceField: this.selectDeviceField.name,
      }
      this.selectFormData.push(data2)

      this.nowOneReflectForm.form = this.selectForm.name
      this.nowOneReflectForm.fieldName = this.selectDeviceField.name
      this.nowOneReflectForm.fieldId = this.selectDeviceField.fieldId
      this.nowReflectData.form.push(this.nowOneReflectForm)

    },
    formSure() {
      this.formDialog = false
      this.$emit("formSure")
    },
    formSearch() {
      this.formFieldSearch = this.$refs.formFieldSearch.value;
      this.formFieldData = this.formFieldDataCopy;
      if (this.formFieldSearch === '') {
        this.formFieldData = this.formFieldDataCopy;
      } else {
        let data = []
        this.formFieldData.map(msg => {
          //拿当前json的id、name、time去分别跟输入的值进行比较
          //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
          if (msg.name.indexOf(this.formFieldSearch) != -1) {
            //然后把当前json添加到list数组中
            data.push(msg);
          }
        })
        this.formFieldData = data
      }
    },
    formSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectForm = row;
    },
    selectFormStatus(row) {
      if (row.flag === '已选中') {
        return false
      }
      return true
    },
  },
}
</script>

<style scoped>

</style>
