<template>
  <el-dialog title="上传文件"
             :close-on-click-modal="false"
             :visible.sync="upFileDialog"
             width="40%"
             append-to-body>
<!--    <span style="width: 10%">文件夹路径:</span>-->
<!--    <el-input v-model="filesPath" style="margin-left: 10px; margin-bottom: 10px;width: 40%"></el-input>-->
<!--    <el-upload-->
<!--        class="upload-demo"-->
<!--        action="/bigdata/addDataAccessTask/fileUpload"-->
<!--        accept=".txt,.doc,.docx,.xls,.xlsx"-->
<!--        :before-remove="beforeRemove"-->
<!--        :before-upload="beforeUpload"-->
<!--        :on-success="upFileSuccess"-->
<!--        :file-list="fileList">-->
<!--      <el-button size="small" type="primary">点击上传</el-button>-->
<!--      <div slot="tip" class="el-upload__tip">只能上传txt/word/excel文件</div>-->
<!--    </el-upload>-->
    <el-form :model="dataForm" :rules="fileDataRule" ref="dataForm" label-width="80px">
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="文件" prop="file">
        <el-upload
            class="upload-demo"
            action="/bigdata/addDataAccessTask/fileUpload"
            accept=".txt,.doc,.docx,.xls,.xlsx"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            :on-success="upFileSuccess"
            :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传txt/word/excel文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
       <el-button @click="upFileDialog = false">取 消</el-button>
       <el-button type="primary" @click="upFileDialogSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { isEmail, isMobile } from '@/utils/validate'
export default {
  name: "upFile",
  props: ["taskName"],
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      dataForm: {
        alias: '',
        email: '',
        mobile: ''
      },
      currOperator: '',
      upFileDialog: false,
      filesPath: '',
      fileList: [],
      config: {},
      formData: undefined,
      data: undefined,
      fileDataRule: {
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  },
  methods: {
    open() {
      this.upFileDialog = true
    },
    close() {
      this.upFileDialog = false
    },
    upFileSuccess(response, file, fileList) {
      console.log(document.getElementsByClassName("el-upload__input")[0].value)
      let fileNameSplit = file.name.split('.')
      let type = fileNameSplit[fileNameSplit.length - 1]
      let flag = 0
      let icon = ''
      if (type === 'txt') {
        flag = 0
        icon = 'iconfont icon-txtwenjian'
      } else if (type === 'docx'||type === 'doc') {
        flag = 1
        type='word'
        icon = 'iconfont icon-Word'
      } else if (type === 'xls'||type === 'xlsx') {
        flag = 2
        type='excel'
        icon = 'iconfont icon-Excel'
      } else {
        this.$message({
          type: 'error',
          message: '不支持该类型文件'
        });
        return
      }
      this.data = {
        icon: icon,
        flag: flag,
        type: type,
        name: file.name,
        filesPath:this.filesPath,
        fileId:0,
      }
      this.fileList = fileList
      this.formData = new FormData()
      this.formData.append('file', file.raw)
      this.formData.append('filesPath', this.filesPath)
      this.formData.append('uid', this.currOperator)
      this.formData.append('type', type)
      this.formData.append('email', this.dataForm.email)
      this.formData.append('mobile', this.dataForm.mobile)
      this.formData.append('alias', this.dataForm.alias)

    },
    beforeRemove(file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "txt" || testmsg === "doc" || testmsg === "docx"|| testmsg === "xls"|| testmsg === "xlsx" );
      if(!extension)  return true;
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "txt" || testmsg === "doc" || testmsg === "docx"|| testmsg === "xls"|| testmsg === "xlsx" );
      if (!extension ) {
        this.$message({
          message: "上传文件类型无效!只能上传txt/word/excel文件",
          type: "warning",
        });
      }
      return extension;
    },
    upFileDialogSure() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.postRequest("/addDataAccessTask/fileUploadMinio",this.formData, this.config).then(response=> {
            console.log(response)
            this.$alert(response.status == 200 ? "上传成功" : "上传失败", '上传结果', {
              confirmButtonText: '确定',
              callback: action => {
                if (response.status == 200) {
                  this.upFileDialog = false
                  this.$refs.dataForm.resetFields()
                  this.data.fileId=response.data
                  this.$emit("upFileSuccess", this.data)
                }
              }
            });
          }).then(error => {
            // console.log(error)
          })
        }
      })
    },

  },
}
</script>

<style scoped>

</style>
