<template>
  <el-dialog title="表单"
             :close-on-click-modal="false"
             :visible.sync="makeFormDialog"
             width="80%"
             append-to-body>
    <div style="height: 100%;width: 100%">
      <iframe id="iframe" ref="frame" scrolling="yes" style="border: 0" width="100%" height="100%" src="http://localhost:8083/">

      </iframe>
    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="makeFormDialog = false">取 消</el-button>
       <el-button type="primary" @click="makeFormSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>

import axios from "axios";

export default {
  name: "makeForm",
  data() {
    return {
      currOperator: '',
      makeFormDialog: false,
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
  },
  methods: {
    open() {
      this.makeFormDialog = true
    },
    close() {
      this.makeFormDialog = false
    },
    makeFormSure(){
      // let data={
      //   name:"",
      //   field:"",
      //   uid:this.currOperator,
      //   formId:0,
      // }
      axios.post("/addDataAccessTask/selectForm").then(response=> {
        console.log(response.data)
        this.$emit("addMakeForm", response.data)
      }).then(error => {
        // console.log(error)
      })
      this.makeFormDialog = false
    },
  },
}
</script>

<style scoped>
/deep/.el-dialog {
  margin: 5vh auto !important;
}

/deep/ .el-dialog__body {
  height: 70vh;
  overflow: auto;
}

</style>
