<template>
  <el-dialog title="添加第三方"
             :visible.sync="addThirdPartyDialog"
             :close-on-click-modal="false"
             width="40%"
             append-to-body>
    <el-form :model="thirdPartyForm" :rules="tdDataRule" ref="dataForm" label-width="80px">
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="thirdPartyForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="第三方名称" prop="name">
        <el-input v-model="thirdPartyForm.name" placeholder="请输入第三方名称"></el-input>
      </el-form-item>
      <el-form-item
          v-for="(data, index) in thirdPartyForm.dataList"
          :label="'数据列' + index"
          :key="data.key"
      >
        <el-input v-model="data.value" placeholder="请输入数据列名"></el-input>
        <el-button @click.prevent="removeData(data)">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="addData">新增数据列</el-button>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="thirdPartyForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="thirdPartyForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>

<!--    <el-form :label-position="thirdPartyLabelPosition" label-width="200px" :model="thirdPartyForm">-->
<!--      <el-form-item style="margin-bottom: 20px" label="第三方名称：">-->
<!--        <el-input v-model="thirdPartyForm.name" placeholder="请输入第三方名称"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="添加传感器数据列表:">-->
<!--        <div-->
<!--            v-for="dataList in thirdPartyForm.dataList"-->
<!--            :key="dataList.id"-->
<!--        >-->
<!--          <el-row style="margin-bottom: 10px">-->
<!--            <el-col :span="8">-->
<!--              <el-input-->
<!--                  style="width: 100%; margin-bottom: 5px"-->
<!--                  v-model="dataList.value"-->
<!--                  placeholder="请输入数据列名称"-->
<!--              ></el-input>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
<!--        <el-row style="margin-bottom: 10px">-->
<!--          <el-col :span="6">-->
<!--            <el-button-->
<!--                style="width: 100%; margin-bottom: 5px;margin-left: 20px"-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                @click="addDataList"-->
<!--                round-->
<!--            >+-->
<!--            </el-button>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
    <span slot="footer" class="dialog-footer">
       <el-button @click="addThirdPartyDialog = false">取 消</el-button>
       <el-button type="primary" @click="addThirdParty">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from '@/utils/validate'
export default {
  name: "addThirdParty",
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      currOperator: '',
      addThirdPartyDialog: false,
      thirdPartyLabelPosition: 'left',
      thirdPartyForm: {
        name: '',
        alias: '',
        email: '',
        mobile: '',
        dataList: [{
          id: 0,
          value: '',
        }
        ],
      },
      tdDataRule: {
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请填写第三方名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
  },
  methods: {
    open() {
      this.addThirdPartyDialog = true
    },
    close() {
      this.addThirdPartyDialog = false
    },
    addThirdParty() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let data={
            alias: this.thirdPartyForm.alias,
            name:this.thirdPartyForm.name,
            dataList:this.thirdPartyForm.dataList[0].value,
            uid:this.currOperator,
            email: this.thirdPartyForm.email,
            mobile: this.thirdPartyForm.mobile,
            thirdPartyId:0,
          }
          for (let i = 1; i < this.thirdPartyForm.dataList.length; i++) {
            data.dataList+='||'+this.thirdPartyForm.dataList[i].value
          }
          this.postRequest("/addDataAccessTask/addThirdParty",data).then(response=> {
            this.$alert(response.status == 200 ? "添加成功" : "添加失败", '添加结果', {
              confirmButtonText: '确定',
              callback: action => {
                if (response.status == 200) {
                  data.thirdPartyId=response.data
                  this.$refs.dataForm.resetFields()
                  this.$emit("addThirdParty", data)
                }
              }
            });
          }).then(error => {
            // console.log(error)
          })
        }
      })
    },
    removeData(item) {
      var index = this.thirdPartyForm.dataList.indexOf(item)
      if (index !== -1) {
        this.thirdPartyForm.dataList.splice(index, 1)
      }
    },
    addData() {
      this.thirdPartyForm.dataList.push({
        id:'',
        value:''
      });
    },
    addDataList(){
      let data = {
        id: this.thirdPartyForm.dataList.length,
        value: '',
      }
      this.thirdPartyForm.dataList.push(data)
    },
  },
}
</script>

<style scoped>

</style>
