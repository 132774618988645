<template>
  <el-dialog title="进行多表联合"
             :close-on-click-modal="false"
             :visible.sync="joinTableDialog"
             width="40%"
             append-to-body>
    <el-form label-width="200px" :model="joinTableForm">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddDetails" class="addButton">添加</el-button>
      <el-table :data="joinTableForm" >
        <el-table-column label="主表名称" align="center" prop="mainTableName">
          <template scope="scope">
            <el-select v-model="scope.row.mainTableName" @change="handleChange(scope)" clearable>
              <el-option
                  v-for="item in computeDifferenceSet()"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                  :disabled="item.disabled">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="主表连接字段" align="center" prop="mainTableKey" >
          <template scope="scope">
            <el-select v-model="scope.row.mainTableKey" clearable >
              <el-option
                  v-for="item in colOptions[scope.$index]"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="从表名称" align="center" prop="subTableName" >
          <template scope="scope">
            <el-select v-model="scope.row.subTableName" clearable @change="handleChangeSub(scope)" >
              <el-option
                  v-for="item in computeDifferenceSet()"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                  :disabled="item.disabled">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="从表连接字段" align="center" prop="subTableKey" >
          <template scope="scope">
            <el-select v-model="scope.row.subTableKey" clearable >
              <el-option
                  v-for="item in colOptionsSub[scope.$index]"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template scope="scope">
            <el-button type="text" @click="removeShowList(scope.$index,scope.row)" class="warningButton">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-form>
    <span slot="footer" class="dialog-footer">
       <el-button @click="joinTableDialog = false">取 消</el-button>
       <el-button type="primary" @click="jointTableSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "joinTable",
  data(){
    return{
      joinTableDialog: false,
      joinTableForm:[
          {'mainTableName':'','subTableName':'','mainTableKey':'','subTableKey':''}],
      tableOptions:[
          {'value':''}
      ],
      colOptions:[{}],
      colOptionsSub:[{}],
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
  },
  methods: {

    open(data) {
      if(window.sessionStorage.getItem("joinTable")!=null){
        this.$confirm('该操作将删除原有关联关系，是否继续?','提示',{
          confirmButtonText: '确认',
          cancelButtonText:'取消',
        }).then(()=>{
          window.sessionStorage.setItem("joinTable",null)
          this.joinTableDialog = true
          this.tableOptions=[];
          this.colOptions=[];
          this.colOptionsSub=[];
          for(let k=0;k<data.length;k++){
            for(let i=0;i<data[k].data.length;i++){
              this.tableOptions.push({
                'name':data[k].data[i].name,
                'value': JSON.stringify( {
                  'dataBaseId':data[k].data[i].dataBaseId,
                  'tableDBType':data[k].data[i].dbType,
                  'tableDB':data[k].data[i].tableDB,
                  'tableName':data[k].data[i].tableName,
                })
              })
            }
          }
        })
      }
      else {
        this.joinTableDialog = true
        this.tableOptions=[];
        this.colOptions=[];
        this.colOptionsSub=[];
        for(let k=0;k<data.length;k++){
          for(let i=0;i<data[k].data.length;i++){
            this.tableOptions.push({
              'name':data[k].data[i].name,
              'value': JSON.stringify( {
                'dataBaseId':data[k].data[i].dataBaseId,
                'tableDBType':data[k].data[i].dbType,
                'tableDB':data[k].data[i].tableDB,
                'tableName':data[k].data[i].tableName,
              })
            })
          }
        }
      }
    },
    close() {
      this.joinTableDialog = false
    },
    handleAddDetails() {
      let obj = {};
      let key =[];
      let subKey=[];
      this.joinTableForm.push(obj);
      this.colOptions.push(key);
      this.colOptionsSub.push(key);
    },
    removeShowList(index,row){
      for(let i=0;i<this.tableOptions.length;i++){
        if(this.tableOptions[i].value===this.joinTableForm[index].subTableName) {
          this.tableOptions[i].disabled=false;
        }
        if(this.tableOptions[i].value===this.joinTableForm[index].mainTableName) {
          this.tableOptions[i].disabled=false;
        }
      }
      this.joinTableForm.splice(index,1);
      this.colOptionsSub.splice(index,1);
      this.colOptions.splice(index,1);
    },
    jointTableSure(){
      let complete=true;
      for(let i=0;i<this.joinTableForm.length;i++){
        if(this.joinTableForm[i].subTableName.length<=0||this.joinTableForm[i].subTableKey.length<=0 ||this.joinTableForm[i].mainTableName.length<=0||this.joinTableForm[i].mainTableKey.length<=0){
          complete=false;
        }
      }
      if(!complete){
        this.$message({
          message:'请补全信息',
          type:'error'
        });
      }
      else {
        let netTableForm=[]
        for(let i=0;i<this.joinTableForm.length;i++){
          let o={}
          o.mainKey=this.joinTableForm[i].mainTableKey;
          o.mainTableName=JSON.parse( this.joinTableForm[i].mainTableName).tableName
          o.subKey=this.joinTableForm[i].subTableKey;
          o.subTableName=JSON.parse( this.joinTableForm[i].subTableName).tableName
          netTableForm.push(o);
        }
        this.$confirm('该操作将删除原有关联关系，并按当前关系进行关联，是否继续?','提示',{
          confirmButtonText: '确认',
          cancelButtonText:'取消',
        }).then(()=>{
          window.sessionStorage.setItem("joinTable",JSON.stringify(netTableForm))
          this.$message({
            type: 'success',
            message:'添加成功'
          })
          this.close()
        })
      }
    },
    handleChange(scope){
      for(let i=0;i<this.tableOptions.length;i++){
        if(this.tableOptions[i].value===scope.row.mainTableName) {
          this.tableOptions[i].disabled = true;
        }
      }
      scope.row.mainTableKey=''
      this.colOptions[scope.$index]=[]
      this.postRequest("/addDataAccessTask/tableField", JSON.parse(scope.row.mainTableName) ).then(response => {
        let o=[]
        for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length / 2; i++) {
          let data = {
            name: response.data[i],
          }
          o.push(data)
        }
        this.colOptions[scope.$index]=o
        this.colOptions.push({})
        this.colOptions.splice(this.colOptions.length,1);
      })
    },
    handleChangeSub(scope){
      for(let i=0;i<this.tableOptions.length;i++){
        if(this.tableOptions[i].value===scope.row.subTableName) {
          this.tableOptions[i].disabled = true;
        }
      }
      scope.row.subTableKey=''
      this.colOptionsSub[scope.$index]=[]
      this.postRequest("/addDataAccessTask/tableField", JSON.parse(scope.row.subTableName) ).then(response => {
        let o=[]
        for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length / 2; i++) {
          let data = {
            name: response.data[i],
          }
          o.push(data)
        }
        this.colOptionsSub[scope.$index]=o
        this.colOptionsSub.push({})
        this.colOptionsSub.splice(this.colOptionsSub.length,1);
      })
    },
    computeDifferenceSet(){
      for(let i=0;i<this.tableOptions.length;i++){
            this.tableOptions[i].disabled=false;
      }
      for(let i=0;i<this.tableOptions.length;i++){
        for(let j=0;j<this.joinTableForm.length;j++){
          if(this.tableOptions[i].value===this.joinTableForm[j].subTableName || this.tableOptions[i].value===this.joinTableForm[j].mainTableName){
            this.tableOptions[i].disabled=true;
          }
        }
      }
      return this.tableOptions;
    }
  }
}
</script>

<style scoped>

</style>