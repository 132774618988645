<template>
  <el-dialog title="传感器类型映射选择表"
             :close-on-click-modal="false"
             :visible.sync="sensorDialog"
             width="50%"
             append-to-body>
    <div>
      <span style="width: 10%">传感器名称:</span>
      <el-input v-model="sensorName" style="margin-left: 10px; width: 40%" disabled></el-input>
      <div style="margin-top: 20px">
        <span style="margin-left: 15%">数据项名称</span>
        <span style="margin-left: 40%">属性列表</span>
        <div style="margin-left: 1%;margin-top: 1%;">
          <div>
            <el-row :gutter="70">
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      prefix-icon="el-icon-search"-->
<!--                      @keyup.native="dFSearch"-->
<!--                      ref="dFieldSearch"-->
<!--                      v-model="deviceFieldSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;"-->
<!--                      placeholder="输入关键字搜索"/>-->
                  <el-table
                      ref="selectDeviceField"
                      :data="deviceFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="deviceFieldSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectDeviceStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据项名称"
                        min-width="70%">
<!--                      <template slot-scope="scope">-->
<!--                        <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
<!--                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
<!--                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
<!--                      </template>-->
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="数据项类型"
                        min-width="70%">
                    </el-table-column>
                  </el-table>
<!--                  <el-button @click="deviceAdd" size="mini" type="primary"-->
<!--                             style="width: 100%;margin-top: 1%;margin-bottom: 1%;" round>添 加-->
<!--                  </el-button>-->
                </div>
              </el-col>
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      @keyup.native="sensorSearch"-->
<!--                      ref="sensorInfoSearch"-->
<!--                      v-model="sensorInfoSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;border-radius:10px;"-->
<!--                      placeholder="输入关键字搜索" prefix-icon="el-icon-search"/>-->
                  <el-table
                      ref="selectSensor"
                      :data="sensorInfoData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="sensorSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectSensorStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据列表"
                        min-width="70%">
<!--                      <template slot-scope="scope">-->
<!--                        <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
<!--                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
<!--                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
<!--                      </template>-->
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-row :gutter="50">
          <el-col :span="17">
            <div>
              <el-table
                  :data="selectSensorData"
                  height="250"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="selectDeviceField"
                    label="已选择设备字段"
                    min-width="40%">
                </el-table-column>
                <el-table-column
                    prop="selectSensorField"
                    label="已选择传感器"
                    min-width="40%">
                </el-table-column>
                <el-table-column label="操作" min-width="20%">
                  <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDeleteSensor(scope.$index, scope.row)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <el-button @click="selectSensorMap" style="width: 100%;height: 5%;padding:5px 10px;font-size: 10px"
                         type="primary" round>确定
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="sensorDialog = false">取 消</el-button>
       <el-button type="primary" @click="sensorSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "sensor",
  props:["workNode","nowReflectData","mapData","nowEachTaskData","taskData","allTaskData","sensorData"],
  data(){
  return{
    sensorDialog:false,
    sensorName: '',
    deviceFieldSearch: '',
    deviceFieldData: [],
    deviceFieldDataCopy: [],
    sensorInfoSearch: '',
    sensorInfoDataCopy: [],
    sensorInfoData:[],
    selectSensor: {},
    selectSensorSecond: {},
    selectDeviceField: {},
    selectDeviceSecondField: {},
    selectSensorData: [],
    nowOneReflectSensor: {},
    oneReflectSensorName_temp: {
      SensorName: '',
      sensor: []
    },
    nowOneReflectSensorName: {},
    oneReflectSensor_temp: {
      sensorField: '',
      sensor:'',
      fieldName: '',
      fieldId: ''
    },
    }
  },
  methods:{

    open(data) {
      this.selectSensorData=data
      this.sensorDialog = true
    },
    close() {
      this.sensorDialog = false
    },
    loadDeviceFieldData(id,data) {
      this.deviceFieldData = [];
      this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          let tmpData = {
            name: response.data.data[i].name,
            type: response.data.data[i].type,
            // fieldId: response.data[i].fieldId,
            fieldId: response.data.data[i].id,
            flag: '未选中',
            seen: false
          }
          if(tmpData.type === undefined || tmpData.type === ""){
            tmpData.type = "未设定"
          }
          console.log("data123:"+tmpData)
          this.deviceFieldData.push(tmpData)
        }
      }).then(error => {
      })
      // let temp = {
      //   coal: this.workNode.parent.parent.parent.parent.data.name,
      //   system: this.workNode.parent.parent.parent.data.name,
      //   subsystem: this.workNode.parent.parent.data.name,
      //   device: this.workNode.parent.data.name,
      //   subDevice: this.workNode.data.name,
      //   subEquipmentId: this.workNode.data.subEquipmentId
      // }
      // this.postRequest("/addDataAccessTask/attributeSubDevice", temp).then(response => {
      //   for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
      //     let data = {
      //       name: response.data[i].name,
      //       fieldId: response.data[i].fieldId,
      //       type: response.data[i].type,
      //       flag: '未选中',
      //       seen: false
      //     }
      //     this.deviceFieldData.push(data)
      //   }
      // }).then(error => {
      // })
      console.log(data)

      for (let i = 0; i < this.sensorData.length; i++) {
        if (id === this.sensorData[i].id) {
          this.sensorName = data.name
          let infoList=data.info.split("||")
          this.sensorInfoData = []
          this.selectSensorData = []
          for (let i = 0; i < infoList.length; i++) {
            let data = {
              name: infoList[i],
              seen: false,
              flag: '未选中'
            }
            this.sensorInfoData.push(data)
          }
          let same = 0
          for (let m = 0; m < this.nowReflectData.Sensor.length; m++) {
            if (this.sensorData[i].sensorName === this.nowReflectData.Sensor[m].SensorName) {
              this.nowOneReflectSensorName = JSON.parse(JSON.stringify(this.nowReflectData.Sensor[m]))
              same = 1
              break
            }
          }
          if (same === 0) {
            this.nowOneReflectSensorName = JSON.parse(JSON.stringify(this.oneReflectSensorName_temp))
            this.nowOneReflectSensorName.SensorName = this.sensorData[i].sensorName
            this.nowReflectData.Sensor.push(this.nowOneReflectSensorName)
          }
          console.log("his.nowReflectData",this.nowReflectData)
          for (let m = 0; m < this.nowReflectData.Sensor.length; m++) {
            if (this.sensorData[i].sensorName === this.nowReflectData.Sensor[m].SensorName) {
              for (let n = 0; n < this.nowReflectData.Sensor[m].sensor.length; n++) {
                if (this.sensorData[i].sensorName === this.nowReflectData.Sensor[m].sensor[n].sensor) {
                  let data = {
                    selectSensor: this.nowReflectData.Sensor[m].sensor[n].sensor,
                    selectSensorField: this.nowReflectData.Sensor[m].sensor[n].sensorField,
                    selectDeviceField: this.nowReflectData.Sensor[m].sensor[n].fieldName,
                    fieldId: this.nowReflectData.Sensor[m].sensor[n].fieldId,
                  }
                  this.selectSensorData.push(data)
                }
              }
              break;
            }
          }

        }
      }

      // if (this.allTaskData.accessSource === '传感器') {

          // this.sensorInfoData = []
          // for (let i = 0; i < this.sensorData.length; i++) {
          //   if (id === this.sensorData[i].id) {
          //     this.sensorName = this.sensorData[i].name
          //     let data = {
          //       name: this.sensorData[i].info,
          //       seen: false,
          //       flag: '未选中'
          //     }
          //     this.sensorInfoData.push(data)
          //   }
          // }
      setTimeout(() => {
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.sensorInfoData.length; j++) {
            if (this.mapData[i].tableData === this.sensorInfoData[j].name&&this.mapData[i].tableName===this.sensorName) {
              this.sensorInfoData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.mapData[i].deviceData === this.deviceFieldData[j].name) {
              this.deviceFieldData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.sensorInfoData.length; i++) {
          if (this.sensorInfoData[i].flag === '已选中2') {
            this.$refs.selectSensor.toggleRowSelection(this.sensorInfoData[i], true)
          }
          this.$options.methods.selectSensorStatus(this.sensorInfoData[i])
        }
        for (let j = 0; j < this.deviceFieldData.length; j++) {
          if (this.deviceFieldData[j].flag === '已选中2') {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[j], true)
          }
          this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
        }
      }, 500)

      this.sensorDialog=true
      // }
    },
    loseFocus(index, row) {
      row.seen = false;
    },
    cellClick(row, column) {
      row.seen = true;
    },
    // dFSearch() {
    //   this.deviceFieldSearch = this.$refs.dFieldSearch.value;
    //   this.deviceFieldData = this.deviceFieldDataCopy;
    //   if (this.deviceFieldSearch === '') {
    //     this.deviceFieldData = this.deviceFieldDataCopy;
    //   } else {
    //     let data = []
    //     this.deviceFieldData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.deviceFieldSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.deviceFieldData = data
    //   }
    // },
    deviceFieldSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectDeviceField = row
      if (this.selectDeviceSecondField.fieldId === undefined) {
        this.selectDeviceSecondField = this.selectDeviceField
      } else {
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceSecondField.fieldId === this.deviceFieldData[i].fieldId) {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.deviceFieldData[i].flag = '未选中'
            break;
          }
        }
        this.selectDeviceSecondField = this.selectDeviceField
      }
      if (row.flag === '未选中' && this.selectDeviceSecondField === this.selectDeviceField) {
        this.selectDeviceSecondField = {}
        this.selectDeviceField = {}
      }
    },
    selectDeviceStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },
    // deviceAdd() {
    //   let data = {
    //     name: '',
    //     seen: false
    //   }
    //   this.deviceFieldData.push(data)
    // },

    handleDeleteSensor(index, row) {
      //删除表内映射信息
      this.selectSensorData.splice(index, 1)
      //删除总映射信息
      for (let i = 0; i < this.mapDataSensor.length; i++) {
        if (row.selectDeviceField === this.mapDataSensor[i].deviceData) {
          this.mapDataSensor.splice(i, 1);
          break;
        }
      }
      //恢复表内附属设备和数据源字段的状态
      for (let i = 0; i < this.deviceFieldData.length; i++) {
        if (row.selectDeviceField === this.deviceFieldData[i].name) {
          this.deviceFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
          }, 500)
        }
      }
      for (let i = 0; i < this.sensorData.length; i++) {
        if (row.selectSensor === this.sensorData[i].name) {
          this.sensorData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectSensor.toggleRowSelection(this.sensorData[i], false)
            this.$options.methods.selectSensorStatus(this.sensorData[i])
          }, 500)
        }
      }

      for (let i = 0; i < this.nowReflectData.Sensor.length; i++) {
        if (row.selectDeviceField === this.nowReflectData.Sensor[i].fieldName) {
          this.nowReflectData.Sensor.splice(i, 1)
          break;
        }
      }
    },
    selectSensorMap() {
      if (this.selectSensor.name === undefined || this.selectDeviceField.name === undefined) {
        this.$message({
          message: '映射关系已经添加或映射内容是空的！',
          type: 'warning'
        });
      } else {
        console.log("1")
        for (let i = 0; i < this.nowReflectData.Sensor.length; i++) {
          if (this.sensorName === this.nowReflectData.Sensor[i].SensorName) {
            console.log("2")
            this.nowOneReflectSensorName= this.nowReflectData.Sensor[i]
          }
        }
        console.log(this.nowOneReflectSensorName)
        if (this.sensorName === this.nowOneReflectSensorName.SensorName) {
          console.log("2")
          this.nowOneReflectSensor = JSON.parse(JSON.stringify(this.oneReflectSensor_temp))
          this.nowOneReflectSensor.sensor = this.sensorName
          this.nowOneReflectSensor.sensorField = this.selectSensor.name
          this.nowOneReflectSensor.fieldName = this.selectDeviceField.name
          this.nowOneReflectSensor.fieldId = this.selectDeviceField.fieldId
          this.nowOneReflectSensorName.sensor.push(this.nowOneReflectSensor)
          //显示一个任务的映射信息
          let data1 = {
            tableData: this.selectSensor.name,
            deviceData: this.selectDeviceField.name,
            tableName: this.sensorName,
            fieldId: this.nowOneReflectSensor.fieldId
          }
          this.mapData.push(data1)
          let data2 = {
            selectSensor: this.sensorName,
            selectDeviceField: this.selectDeviceField.name,
            selectSensorField: this.selectSensor.name,
            fieldId: this.nowOneReflectSensor.fieldId
          }
          this.selectSensorData.push(data2)
        }

        for (let i = 0; i < this.sensorInfoData.length; i++) {
          if (this.selectSensor.name === this.sensorInfoData[i].name) {
            this.sensorInfoData[i].flag = '已选中2'
            this.$options.methods.selectSensorStatus(this.sensorInfoData[i])
            break;
          }
        }
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceField.fieldId === this.deviceFieldData[i].fieldId) {
            this.deviceFieldData[i].flag = '已选中2'
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
            break;
          }
        }
        this.selectSensor = {}
        this.selectDeviceField = {}
        this.selectSensorSecond = {}
        this.selectDeviceSecondField = {}

        for (let i = 0; i < this.nowReflectData.Sensor.length; i++) {
          if (this.sensorName === this.nowReflectData.Sensor[i].SensorName) {
            this.nowReflectData.Sensor[i] = JSON.parse(JSON.stringify(this.nowOneReflectSensorName))
          }
        }
        this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))


        for (let i = 0; i < this.taskData.length; i++) {
          if (this.taskData[i].subEquipmentId === this.nowEachTaskData.subEquipmentId) {
            this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
          }
        }
        this.allTaskData.taskData = this.taskData
      }
    },
    sensorSure() {
      this.sensorDialog = false
      this.$emit("sensorSure",this.selectSensorData)
    },
    // sensorSearch() {
    //   this.sensorInfoSearch = this.$refs.sensorInfoSearch.value;
    //   this.sensorInfoData = this.sensorInfoDataCopy;
    //   if (this.sensorInfoSearch === '') {
    //     this.sensorInfoData = this.sensorInfoDataCopy;
    //   } else {
    //     let data = []
    //     this.sensorInfoData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.sensorInfoSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.sensorInfoData = data
    //   }
    // },
    sensorSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      console.log(row)
      this.selectSensor = row
      if (this.selectSensorSecond.name === undefined) {
        this.selectSensorSecond = this.selectSensor
      } else {
        for (let i = 0; i < this.sensorInfoData.length; i++) {
          if (this.selectSensorSecond.name === this.sensorInfoData[i].name) {
            this.$refs.selectSensor.toggleRowSelection(this.sensorInfoData[i], false)
            this.sensorInfoData[i].flag = '未选中'
            break;
          }
        }
        this.selectSensorSecond = this.selectSensor
      }
      if (row.flag === '未选中' && this.selectSensorSecond === this.selectSensor) {
        this.selectSensorSecond = {}
        this.selectSensor = {}
      }
      this.selectSensor = row;
    },
    selectSensorStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },
    cellClass(row) {
      console.log("***", row)
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
  },

}
</script>

<style scoped>
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '操作';
  text-align: center;
  line-height: 50px;
}
</style>
